<template>
  <b-row>
    <b-col>
      <b-card>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity"
          :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"
        ></loading>
        <div class="mb-3">
          <download-excel
            class="btn btn-primary pull-right ml-3"
            :fetch="fetchData"
            :fields="download.header"
            :before-generate="startDownload"
            :before-finish="finishDownload"
            :name="'Зээлийн түүх.xls'"
            v-if="items != undefined && items.length > 0"
          >
            <b-spinner small type="grow" v-show="download.loading"></b-spinner>
            <span v-show="download.loading" class="ml-1">Уншиж байна</span>
            <i class="fa fa-download" v-show="!download.loading"></i>
            <span v-show="!download.loading" class="ml-1">Excel татах</span>
          </download-excel>

          <div class="pull-right">
            <b-form-select
              v-model="query.per_page"
              :options="[5, 10, 25, 100]"
              v-if="items != undefined && items.length > 0"
            >
            </b-form-select>
          </div>
          <b-row>
            <b-col
              class="col-md-3"
              :class="{ 'pl-0': items != undefined && items.length > 0 }"
            >
              <multiselect
                v-model="selectedLoanApplicationType"
                :options="loanApplicationTypeList"
                :searchable="true"
                placeholder="Зээлийн хүсэлтийн төрөл"
                :close-on-select="true"
                :option-height="10"
                track-by="id"
              >
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option.name }}
                </template>
                <template slot="option" slot-scope="props">
                  {{ props.option.name }}
                </template>
              </multiselect>
            </b-col>
            <b-col
                class="col-md-3"
                :class="{ 'pl-0': items != undefined && items.length > 0 }"
            >
              <multiselect
                  v-model="selected_employee"
                  :options="employees"
                  :searchable="true"
                  placeholder="Ажилтан"
                  :close-on-select="true"
                  :option-height="10"
                  track-by="id"
              >
                <template slot="singleLabel" slot-scope="props">
                  @{{ props.option.firstname }}
                </template>
                <template slot="option" slot-scope="props">
                  @{{ props.option.firstname }}
                </template>
              </multiselect>
            </b-col>
            <b-col class="col-md-3">
              <multiselect
                v-model="selected_status"
                :options="statuses"
                :searchable="true"
                placeholder="Төлөв"
                :close-on-select="true"
                :option-height="10"
                track-by="id"
              >
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option.name }}
                </template>
                <template slot="option" slot-scope="props">
                  {{ props.option.name }}
                </template>
              </multiselect>
            </b-col>
            <b-col class="col-md-3">
              <b-form @submit.stop.prevent="submitSearch">
                <b-input-group>
                  <b-input-group-prepend is-text
                    ><i class="fa fa-search"></i
                  ></b-input-group-prepend>
                  <b-form-input
                    v-model="search"
                    placeholder="Хайх"
                  ></b-form-input>
                  <b-input-group-append
                    ><b-button variant="primary" type="submit"
                      >Хайх</b-button
                    ></b-input-group-append
                  >
                  <b-input-group-append
                    ><b-button variant="secondary" @click="loadData"
                      ><i class="fa fa-refresh"></i
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form>
            </b-col>
          </b-row>
        </div>
        <div
          class="table-responsive"
          v-if="items != undefined && items.length > 0"
        >
          <b-table
            id="filter-table"
            stacked="md"
            @sort-changed="sortingChanged"
            hover
            bordered
            small
            responsive="sm"
            :items="items"
            :fields="columns"
            no-local-sorting
          >
            <template v-slot:cell(index)="data">
              {{ data.index + pagination.from }}
            </template>
            <template v-slot:cell(status_name)="data">
              <span
                class="badge"
                :class="getClassByStatus(data.item.status_name)"
                >{{ data.item.status_name }}</span
              >
            </template>

            <template v-slot:cell(product_amount)="data">
              {{ data.item.product_amount|currecryZero }}
            </template>
             <template v-slot:cell(loan_amount)="data">
              {{ data.item.loan_amount|currecryZero }}
            </template>
            <template v-slot:cell(actions)="data">
              <b-button-group
                v-if="data.index <= pagination.to - pagination.from"
              >
                <b-button
                  size="sm"
                  variant="primary"
                  :to="{
                    name: 'admin.app-numur.loan-request.detail',
                    params: { id: data.item.id }
                  }"
                >
                  <i class="fa fa-id-card"></i>
                </b-button>
                <b-button
                  size="sm"
                  variant="primary"
                  :to="{
                    name: 'admin.customer.detail',
                    params: { id: data.item.customer_id }
                  }"
                >
                  <i class="fa fa-user"></i>
                </b-button>
              </b-button-group>
            </template>
          </b-table>

          <div slot="footer" class="mb-0 mt-3">
            <nav class="pull-right" v-if="pagination.total > query.per_page">
              <b-pagination
                class="mb-0"
                :total-rows="pagination.total"
                :per-page="query.per_page"
                v-model="query.page"
              />
            </nav>
            <p class="mb-0">
              {{ pagination.total }} өгөгдлөөс
              {{ pagination.to ? pagination.from : 0 }} -
              {{ pagination.to }} өгөгдөл харагдаж байна.
            </p>
          </div>
        </div>

        <b-alert variant="warning" v-else show class="mb-0">
          Мэдээлэл байхгүй байна
        </b-alert>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { mapGetters } from 'vuex'
export default {
  name: "Customers.loanAccountHistory",
  computed: {
    ...mapGetters([
      'email'
    ])
  },
  components: { axios },
  props: ["customerId"],
  data: function() {
    return {
      isLoading: false,
      filterShow: false,
      isOverduePayment: false,
      query: {
        id: 0,
        page: 1,
        per_page: 10,
        orderDirection: "DESC",
        orderBy: "id",
        search: ""
      },
      payment: {
        over: "0",
        under: "0",
        undueLoss: "0"
      },
      pagination: {
        total: 0,
        from: 0,
        to: 0
      },
      orderDesc: false,
      items: [],
      selected_status: null,
      selected_employee: null,
      statuses: [],
      employees: [],
      loanApplicationTypeList: [],
      selectedLoanApplicationType: null,
      search: "",
      download: {
        loading: false,
        header: {}
      },
      columns: [
        { label: "№", key: "index", class: "text-center" },
        {
          label: "Овог",
          key: "lastname",
          sortable: true,
          sort_key: "lastname"
        },
        {
          label: "Нэр",
          key: "firstname",
          sortable: true,
          sort_key: "firstname"
        },
        {
          label: "Регистр",
          key: "register_number",
          sortable: true,
          sort_key: "register_number"
        },
        {
          label: "Барааны дүн",
          key: "product_amount"
        },
        {
          label: "Хүсэж буй зээлийн дүн",
          key: "loan_amount"
        },
        {
          label: "Эдийн засагч",
          key: "e_firstname",
          sortable: true,
          sort_key: "e_name"
        },
        {
          label: "Хүсэлт гаргасан огноо",
          key: "sent_at",
          sortable: true,
          sort_key: "sent_at"
        },
        {
          label: "Төлөв",
          key: "status_name",
          sortable: true,
          sort_key: "status"
        },
        {
          label: "Тайлбар",
          key: "description"
        },
        { label: "Үйлдэл", key: "actions", class: "text-center" }
      ],
      isActiveLoan: false
    };
  },
  created: function() {
    this.initData();
    this.$data.query.id = this.customerId;
    this.$data.download.header = {};
    for (let i in this.$data.columns) {
      let _field = this.$data.columns[i];
      if (_field.label != "Үйлдэл")
        this.$data.download.header[_field.label] = _field.key;
    }
    this.$data.download.header["Эдийн засагч"] = "e_firstname";
    this.loadData();
  },
  watch: {
    query: {
      handler(query) {
        this.loadData();
      },
      deep: true
    },
    selected_status: {
      handler(selected_status) {
        if (selected_status) this.$data.query.status = selected_status.id;
        else this.$data.query.status = null;
        this.loadData();
      },
      deep: true
    },
    selected_employee: {
      handler(selected_employee) {
        if (selected_employee) this.$data.query.e_id = selected_employee.id;
        else this.$data.query.e_id = null;
        this.loadData();
      },
      deep: true
    },
    selectedLoanApplicationType: {
      handler() {
        this.loadData();
      },
      deep: true
    },
  },
  methods: {
    getClassByStatus: function(status) {
      return {
        Илгээсэн: "badge-warning text-white",
        Баталгаажсан: "badge-success",
        Татгалзсан: "badge-danger",
        "Судалж байгаа": "badge-info text-white",
        Шинэ: "badge-secondary",
        "Цуцалсан": "badge-danger",
        "Зээл олгосон": "badge-success",
        "Муу зээлийн түүхтэй": 'badge-danger',
        "Хүлээж авсан": 'badge-info',
        "Шалгаж байгаа": 'badge-warning'
      }[status];
    },
    initData: function() {
      this.$http
        .get(this.$config.APP_NUMUR_URL + "api/get_status_list", {
          emulateJSON: true
        })
        .then(
          (response) => {
            // //console.log(response);
            this.isLoading = false;
            if (response.body.success) {
              this.$data.statuses = response.body.data;
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );

      this.$http
        .get(this.$config.APP_NUMUR_URL + "api/get_economist_list", {
          params: { loan_type: 'COURSE'},
          emulateJSON: true
        })
        .then(
          (response) => {
            if (response.body.success) {
              this.$data.employees = response.body.data;
            }
          },
          (response) => {}
        );

      this.$http
          .get(this.$config.APP_NUMUR_URL + "api/get_loan_application_type_list", {
            params: { email: this.email},
            emulateJSON: true
          })
          .then(
              (response) => {
                if (response.body.success) {
                  this.$data.loanApplicationTypeList = response.body.data;
                  this.$data.selectedLoanApplicationType = response.body.data.length > 0 ? response.body.data[0]: null
                }
              },
              (response) => {}
          );
    },
    loadData: function() {
      this.checkSession();
      this.isLoading = true;
      console.log(this.$data.query)
      if (this.$data.selectedLoanApplicationType) {
        this.$http
            .get(this.$config.APP_NUMUR_URL + "api/loan_request_list?loan_application_type_code=" + this.$data.selectedLoanApplicationType.code, {
              params: this.$data.query,
              emulateJSON: true
            })
            .then(
                (response) => {
                  console.log(response)
                  this.isLoading = false;
                  this.$data.items = response.body.data.items;
                  this.$data.pagination.total = response.body.data.total;
                  this.$data.pagination.from = this.$data.query.per_page * (this.$data.query.page - 1) + 1;
                  this.$data.pagination.to = this.$data.query.per_page * (this.$data.query.page - 1) + response.body.data.items.length;
                },
                (response) => {
                  this.isLoading = false;
                }
            );
      }
    },
    sortingChanged(ctx) {
      let _field = "";
      for (let i in this.fields) {
        if (this.fields[i].key == ctx.sortBy) {
          this.$data.query.orderBy = this.fields[i].sort_key;
          break;
        }
      }
      this.$data.query.orderDirection = ctx.sortDesc ? "ASC" : "DESC";
    },
    submitSearch: function() {
      this.$data.query.search = this.$data.search;
    },
    async fetchData() {
      if (this.download.loading) {
        this.download.loading = true;
        const response = await axios.get(
          this.$config.APP_NUMUR_URL + "api/furniture/loan_request_list",
          {
            params: {
              page: 1,
              per_page: 0,
              orderDirection: this.$data.query.orderDirection,
              orderBy: this.$data.query.orderBy,
              search: this.$data.query.search,
              e_id: this.$data.query.e_id,
              status: this.$data.query.status
            },
            emulateJSON: true
          }
        );
        if (response.data.status == 500) {
          this.showToast(
            "Алдаа",
            "Алдаа гарсан байна. Дахин оролдоно уу",
            "danger"
          );
          this.download.loading = false;
          return [];
        }

        let _result = [];
        for (let i in response.data.data) {
          let _item = response.data.data[i];
          _item.index = parseInt(i) + 1;
          _item.closingAmount = "";
          _result.push(_item);
        }
        return _result;
      }
      return [];
    },
    startDownload() {
      this.download.loading = true;
    },
    finishDownload() {
      this.download.loading = false;
    }
  }
};
</script>
